.login {
	> .grid-container > .grid-x {
		height: 100vh;
	}
}

.login__wrapper {
	label {
		margin-bottom: rem-calc(16);
	}
}

.top-bar {
	.menu {
		li > span {
			display: block;
			padding: $global-menu-padding;
			line-height: 1;
		}
	}
}

.content {
	height: 100%;
	padding-top: rem-calc(80);
	padding-bottom: rem-calc(80);
}

.results__table {
	.icon {
		margin-right: 14px;
	
		&:last-child {
			margin-right: 0;
		}
	
		&.delete {
			color: $alert-color;
		}
	}
}

.input--form {
	.control__buttons--wrapper {
		padding-top: 24px;
	}
	.button {
		&.cancel {
			margin-left: 20px;
		}
	}
	// select[multiple] {
	// 	height: 100%;
	// }
	label {
		max-width: 100%;
		font-weight: bold;
	}
	
	&#plaqueForm {
		fieldset {
			legend {
				background: lighten($primary-color, 10%);
				color: $white;
				padding: 20px;
				width: 100%;
			}
			> .grid-x {
				padding: 0 20px;
			}
		}
		textarea {
			&:not(.select2-search__field) {
				height: 300px;
			}
		}
		.tox-tinymce {
			margin-bottom: 20px;
		}
		
		.plaque--image {
			max-height: 250px;
			width: auto;
			margin-bottom: 2rem;
		}
	}
}

// .choices__list--single > .choices__item:not(:empty) + .choices__placeholder {
// 	display: none;
// }
//
// .choices {
// 	&[data-type*="select-one"] {
// 		line-height: 1;
// 		margin-bottom: 1rem;
// 	}
// }
// .choices__inner {
// 	border-radius: $select-radius;
// 	background: $select-background;
// 	box-shadow: $input-shadow;
// 	border: $input-border;
// 	font-size: $input-font-size;
//
// 	.is-open & {
// 		border-radius: $select-radius;
// 	}
//
// 	.choices[data-type*="select-one"] & {
// 		padding: rem-calc(6 8 7);
// 		min-height: auto;
// 		line-height: $input-line-height;
// 	}
// }
// .choices__list--single {
// 	padding: 0;
// }
//
// .choices__list--dropdown,
// .choices__list[aria-expanded] {
// 	.is-open & {
// 		width: min-content;
// 	}
// 	.choices__list {
// 		width: max-content;
// 	}
// }

.ui-sortable-handle {
	cursor: move;
}

.select2-container--default {
	margin-bottom: $form-spacing;
	font-weight: normal;
	.select2-selection--single {
		border: none;
		height: auto;

		.select2-selection__rendered {
			border-radius: $select-radius;
			background: $select-background;
			box-shadow: $input-shadow;
			border: $input-border;
			font-size: $input-font-size;
			padding: rem-calc(6 8 7);
			line-height: $input-line-height;

			.is-invalid-label & {
				@include form-input-error;
			}
		}
		.select2-selection__arrow {
			width: 24px;
			height: 38px;

			b {
				border-color: $select-triangle-color transparent transparent transparent;
			}
		}
	}
	
	.select2-selection__rendered {
		margin-left: 0;
		margin-bottom: 0;
	}
	.select2-dropdown {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	}
	.select2-search__field {
		margin-bottom: 0;
	}
	
	.select2-selection--multiple {
		min-height: 37px;
		border-radius: $global-radius;
		padding-bottom: 0;
		
		.select2-search__field {
			margin-bottom: 0;
			margin-top: 0;
			height: 37px !important;
			min-height: 37px;
			line-height: calc(37/14);
			
		}
	}
	.select2-selection__choice__remove {
		height: 100%;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}

	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__rendered {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}

.is-invalid-label {
	.select2-selection--single {
		margin-bottom: 1.3rem;
	}
}

.dataTables_filter,
.dataTables_length {
	margin-bottom: 1.5rem;
}
.dataTables_filter {
	label {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	input {
		margin: 0 0 0 6px;
	}
}
.dataTables_length {
	margin-bottom: 0;
	label {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	select {
		width: auto;
		max-width: 150px;
		margin: 0 6px;
	}
}
.dataTables_info {
	font-size: 0.8rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.dataTables_paginate {
	.pagination {
		margin-bottom: 0;
	}
}

.admin {
	.results__table {
		th {
			text-align: left;
		}
	}
}
