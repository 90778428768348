/******************************************************************
	
Stylesheet: Main Stylesheet

Here's where the magic happens. Here is where you import
all of your Sass files so they can compile into one
CSS file. 

******************************************************************/
@use 'sass:math';

// Import local Foundation overrides - Don't link to Bower version as it will be overwritten whenever updated
@import "settings";
@import "mixins";

@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/thin";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

// Import foundation.scss from vendor files
@import "../../../node_modules/foundation-sites/scss/foundation";

// Select the components you want to use
@include foundation-global-styles;

// Select the grid you would like to use
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;

@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-prototype-spacing;

@import "../../../node_modules/select2/src/scss/core";
@import "../../../node_modules/toastr/toastr";
@import "global";
